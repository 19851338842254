<template>
  <main>
    <CCard>
      <CAlert
        id="ChooseNotice"
        v-if="(ChooseIndex.length > 0)"
        color="info"
        :fade="false"
      >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseIndex.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
        <div class="ButtonGroup">
          <CButton color="light" size="sm" class="mr-2" @click="ChooseIndex = [];CheckAllValue = false">
            {{ $t('Global.Cancel') }}
          </CButton>
          <CButton class="d-none" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
        </div>
      </CAlert>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Order/Travel') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right" />
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Buyer')">
              {{ $t('Order.Buyer') }}: {{ SearchFilter.Buyer || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('BuyerPhone')">
              {{ $t('Order.BuyerPhone') }}: {{ SearchFilter.BuyerPhone || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('OrderNum')">
              {{ $t('Order.OrderNum') }}: {{ SearchFilter.OrderNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Payment')">
              {{ $t('Order.PaymentType') }}: {{ SearchFilter.Payment.map(item => item.label).join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PerPage')">
              {{ $t('Order.Filter.PerPage') }}: {{ Pagination.PerPage }} {{ $t('Global.OrderUnit') }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="OrderList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.OrderNum" :value="item.OrderNum"
                  v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #OrderNum="{item}">
            <td>
              <a href="#" @click="OpenWindow(`/order/ticket/${item.OrderNum}`)" class="text-info">
                {{ item.OrderNum }}
              </a>
              <p class="mb-0">
                <CBadge class="mr-1" :color="(item.UserID === '0' ? 'warning' : 'success')">{{ (item.UserID === '0' ? '訪客' : '會員') }}</CBadge>
                <CBadge v-if="typeof item.MemberInfo.Country !== 'undefined' && !item.MemberInfo.Country.includes('TW')" class="mr-1" color="info">海外</CBadge>
                <template v-if="Array.isArray(item.Variable.Tags)">
                  <CBadge v-for="Tag in item.Variable.Tags" :key="Tag" class="mr-1" color="danger">{{ $i18n.messages[$i18n.locale].Tags[Tag] ? $i18n.messages[$i18n.locale].Tags[Tag] : Tag }}</CBadge>
                </template>
              </p>
            </td>
          </template>
          <template #OrderInfo="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.CreateTime') }}</b>: {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
              <p class="mb-0">
                <b>{{ $t('Order.Origin') }}</b>: {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span></p>
            </td>
          </template>
          <template #Buyer="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.Buyer') }}</b>:
                <a v-if="item.UserID !== '0'" href="javascript:void(0)" @click="OpenUserModal(item.UserID)" class="text-info">
                  {{ item.MemberInfo.Name }}
                </a>
                <span v-else>{{ item.MemberInfo.Name }}</span>
              </p>
              <p class="mb-0"><b>{{ $t('Order.Phone') }}</b>: {{ item.MemberInfo.Phone }}</p>
            </td>
          </template>
          <template #Amount="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.RealAmount') }}</b>: {{ $store.state.currency + '$' }}{{ item.RealAmount }}</p>
              <p class="mb-0"><b>{{ $t('Order.Amount') }}</b>: <span
                class="text-danger">{{ $store.state.currency + '$' }}{{ item.TotalAmount }}</span></p>
            </td>
          </template>
          <template #PaymentTypeSystem="{item}">
            <td>
              <p class="mb-0"><b>{{ $t('Order.PaymentType') }}</b>: {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}</p>
              <p class="mb-0"><b>{{ $t('Order.PaymentSystem') }}</b>: {{ $t('Payment.PaymentSystem.' + item.Payment.System) }}</p>
            </td>
          </template>
          <template #PaymentType="{item}">
            <td>
              {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})
            </td>
          </template>
          <template #LogisticsType="{item}">
            <td>
              {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})
            </td>
          </template>
          <template #PaymentStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Payment]">
                {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
              </CBadge>
            </td>
          </template>
          <template #LogisticsStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Logistics]">
                {{ $t('Order.LogisticsStatusList.' + item.Status.Logistics) }}
              </CBadge>
            </td>
          </template>
          <template #OrderStatus="{item}">
            <td>
              <CBadge :color="StatusClassOption[item.Status.Order]">
                {{ $t('Order.OrderStatusList.' + item.Status.Order) }}
              </CBadge>
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton color="success" size="sm" class="mr-1" @click="$router.push(`/order/ticket/${item.OrderNum}`)">
                <CIcon name="cil-check-circle" size="sm" class="mr-1 mt-0" />{{ $t('Order.Info') }}
              </CButton>
              <CButton color="info" size="sm" class="mr-1" @click="OpenNotifyModal('', item)">
                <CIcon name="cil-envelope-closed" size="sm" class="mr-1 mt-0" />{{ $t('Global.EmailNotify') }}
              </CButton>
<!--              <CButton color="warning" size="sm" class="mr-1" @click="OpenSMSModal('Unpaid', item)">-->
<!--                <CIcon name="cil-short-text" size="sm" class="mr-1 mt-0" />{{ $t('Global.SMSNotify') }}-->
<!--              </CButton>-->
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages" @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :size="(ShowFilter !== 'All' ? 'md' : 'lg')" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit"
            @update:show="ChangePage()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="date" :model-config="{timeAdjust: '00:00:00'}" :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="date" :model-config="{timeAdjust: '23:59:59'}" :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.TimeRangeOptions') }}
        </CCol>
        <CCol sm="9">
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD'), $dayjs().add(1, 'day').format('YYYY-MM-DD'))">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            昨日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(6, 'day').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            本週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(2, 'week').format('YYYY-MM-DD'), $dayjs().subtract(1, 'week').format('YYYY-MM-DD'))">
            上週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').format('YYYY-MM-DD'), $dayjs().endOf('month').format('YYYY-MM-DD'))">
            本月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().startOf('month').format('YYYY-MM-DD'))">
            上月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            近一個月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(3, 'month').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            近三個月
          </CButton>
        </CCol>
        <CCol sm="12">
          <hr>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.ReceiverDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.ReceiverDate" mode="date" :model-config="{timeAdjust: '00:00:00'}">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD'), $dayjs().add(1, 'day').format('YYYY-MM-DD'), 'ReceiverDate')">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'), 'ReceiverDate')">
            昨日
          </CButton>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'OrderNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.OrderNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.OrderNum"
            v-model="SearchFilter.OrderNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.OrderNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Buyer'" v-model="SearchFilter.Buyer" :label="$t('Order.Buyer')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'BuyerPhone'" v-model="SearchFilter.BuyerPhone" :label="$t('Order.BuyerPhone')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'BuyerEmail'" v-model="SearchFilter.BuyerEmail" :label="$t('Order.BuyerEmail')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Receiver'" v-model="SearchFilter.Receiver" :label="$t('Order.Receiver')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ReceiverPhone'" v-model="SearchFilter.ReceiverPhone" :label="$t('Order.ReceiverPhone')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ReceiverEmail'" v-model="SearchFilter.ReceiverEmail" :label="$t('Order.ReceiverEmail')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'ProductName'" v-model="SearchFilter.ProductName" :label="$t('Order.Filter.ProductName')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.SeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SeriesNum"
            v-model="SearchFilter.SeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.SeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'InvoiceType'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.InvoiceType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.InvoiceType"
            v-model="SearchFilter.InvoiceType"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="InvoiceTypeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'InvoiceNumber'" v-model="SearchFilter.InvoiceNumber" :label="$t('Order.Invoice.Number')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Payment'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.PaymentType') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Payment"
            v-model="SearchFilter.Payment"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="PaymentTypeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'PaymentSystem'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.PaymentSystem') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.PaymentSystem"
            v-model="SearchFilter.PaymentSystem"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="PaymentSystemList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" horizontal :label="$t('Order.Filter.Sort')" :options="SortOption" v-model="Order" :value.sync="Order" :placeholder="$t('Global.PleaseSelect')"/>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component
      :is="Component"
      :Toggle.sync="ToggleModal"
      :OrderData.sync="SelectOrderData"
      :AllCondition.sync="AllCondition"
      :SelectSystem.sync="SelectSystem"
      :OrderNum.sync="SelectOrderNum"
      :UserID="UserID"
      :UserType="UserType"
      :MemberInfo="SelectOrderData.MemberInfo"
      :MessageType.sync="MessageType"
      @Success="GetList()"
    />
  </main>
</template>

<route>
{
"meta": {
"label": "旅遊與交通票"
}
}
</route>

<script>
export default {
  name: 'OrderListUnpaid',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Component: null,
      List: [],
      Features: [],
      StatisticsSwitch: 'Total',
      SubTotalStatistics: {},
      TotalStatistics: {},
      ShowFilter: 'All',
      MessageType: '',
      SearchFilterType: null,
      SearchFilter: {
        StartTime: '',
        EndTime: '',
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        SeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: [],
        Country: []
      },
      AllCondition: false,
      AlertModel: false,
      FilterModel: false,
      ToggleModal: false,
      SelectOrderNum: '',
      SelectOrderData: {},
      SelectSystem: '',
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      SearchAllTimeRange: true,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Order.NoResults'),
        noItems: this.$t('Order.NoItems')
      },
      PageList: [
        { label: this.$t('Offer.OfficialWeb'), value: 'Default' }
      ],
      UserID: '',
      UserType: ''
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'OrderNum', label: this.$t('Order.OrderNum')},
        {key: 'OrderInfo', label: this.$t('Order.Info')},
        {key: 'Buyer', label: this.$t('Order.Buyer')},
        {key: 'Amount', label: this.$t('Order.Amount')},
        {key: 'PaymentTypeSystem', label: this.$t('Order.PaymentTypeSystem')},
        {key: 'PaymentStatus', label: this.$t('Order.PaymentStatus')},
        {key: 'OrderStatus', label: this.$t('Order.OrderStatus')},
        {key: 'Action', label: ''},
      ]
    },
    SortOption() {
      return Object.keys(this.$t('Order/List.SortType')).map(type => {
        return {
          value: type,
          label: this.$t('Order/List.SortType')[type]
        }
      })
    },
    PerPageOption() {
      return Object.keys(this.$t('Order/List.PerPageOption')).map(option => {
        return {
          value: option,
          label: this.$t('Order/List.PerPageOption')[option]
        }
      })
    },
    InvoiceTypeList() {
      return Object.keys(this.$t('Order.InvoiceType')).map(type => {
        return {
          value: type,
          label: this.$t('Order.InvoiceType')[type]
        }
      })
    },
    PaymentTypeList() {
      return Object.keys(this.$t('Payment.PaymentMethods')).map(status => {
        return {
          value: status,
          label: this.$t('Payment.PaymentMethods')[status]
        }
      })
    },
    LogisticsTypeList() {
      return Object.keys(this.$t('Logistics.LogisticsMethods')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.LogisticsMethods')[status]
        }
      })
    },
    PaymentSystemList() {
      return Object.keys(this.$t('Payment.PaymentSystem')).map(status => {
        return {
          value: status,
          label: this.$t('Payment.PaymentSystem')[status]
        }
      })
    },
    LogisticsSystemList() {
      return Object.keys(this.$t('Logistics.LogisticsSystem')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.LogisticsSystem')[status]
        }
      })
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    },
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Order || []
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'SeriesNum':
            case 'PaymentStatus':
            case 'LogisticsStatus':
            case 'OrderStatus':
            case 'InvoiceType':
            case 'OfferType':
            case 'PaymentSystem':
            case 'LogisticsSystem':
              this.SearchFilter[param] = this[`${param}List`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Payment':
            case 'Logistics':
              this.SearchFilter[param] = this[`${param}TypeList`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'StartTime':
            case 'EndTime':
              this.SearchFilter[param] = parseInt(this.$route.query[param]) * 1000
              break
            case 'Origin':
            case 'Country':
              this.SearchFilter[param] = this.PageList.filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'Buyer':
            case 'Receiver':
            case 'BuyerPhone':
            case 'ReceiverPhone':
            case 'BuyerEmail':
            case 'ReceiverEmail':
            case 'ProductName':
            case 'SearchAllTimeRange':
            case 'ReceiverDate':
              this.SearchFilter[param] = this.$route.query[param]
              break
            default:
              this.SearchFilter[param] = this.$route.query[param].split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const RequestQuery = {
        Taxonomy: 'ProductOrder',
        Page: this.$route.query.page || this.Pagination.Page,
        PerPage: this.$route.query.perPage || this.Pagination.PerPage,
        Order: this.$route.query.order || this.Order,
        OrderStatus: 'Y'
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.ChooseIndex = []
      this.List = []
      this.SubTotalStatistics = {}
      this.TotalStatistics = {}
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/order/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.SubTotalStatistics = data.subTotalStatistics || {}
        this.TotalStatistics = data.totalStatistics || {}
        this.Pagination = data.pagination
        this.Pagination = {
          Page: data.pagination.Page,
          PerPage: data.pagination.PerPage.toString(),
          TotalItems: data.pagination.TotalItems,
          TotalPages: data.pagination.TotalPages
        }
        this.SearchFilter.StartTime = data.pagination.TimeRange.StartTime
        this.SearchFilter.EndTime = data.pagination.TimeRange.EndTime
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        switch (typeof this.SearchFilter[param]) {
          case "object":
            if (Array.isArray(this.SearchFilter[param]) && this.SearchFilter[param].length > 0) {
              switch (param) {
                case 'PaymentStatus':
                case 'LogisticsStatus':
                case 'OrderStatus':
                case 'Payment':
                case 'Logistics':
                case 'PaymentSystem':
                case 'LogisticsSystem':
                case 'InvoiceType':
                case 'OfferType':
                case 'Origin':
                case 'Country':
                  query[param] = this.SearchFilter[param].map(item => item.value).join(',')
                  break
                default:
                  query[param] = this.SearchFilter[param].join(',')
                  break
              }
            } else {
              switch (param) {
                case 'StartTime':
                case 'EndTime':
                  query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                  break
                case 'ReceiverDate':
                  query[param] = this.$dayjs(this.SearchFilter[param]).format('YYYY-MM-DD')
                  break
                case 'UTM':
                  for (const [key, val] of Object.entries(this.SearchFilter[param])) {
                    if (val !== '') query[key] = val
                  }
                  break
              }
            }
            break
          case "string":
          case "number":
            switch (param) {
              case 'StartTime':
              case 'EndTime':
                query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                break
              default:
                if (this.SearchFilter[param] !== '') {
                  query[param] = this.SearchFilter[param]
                }
                break
            }
            break
        }
      })
      return this.$router.replace({
        path: '/order/process/travel',
        query
      }).then(() => {
        this.Init()
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.OrderNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      if (id === 'OrderNum') {
        newTag = newTag.trim().toUpperCase()
      }
      this.SearchFilter[id].push(newTag)
    },
    ChangeCountry(newTag, id) {
      id = id.replace('SearchFilter.', '')
      const TaiwanCode = ['TW', 'TW-MAIN', 'TW-OUTLYING']
      if (TaiwanCode.includes(newTag.value)) {
        TaiwanCode.splice(TaiwanCode.findIndex(code => code === newTag.value), 1)
        TaiwanCode.forEach((code) => {
          this.SearchFilter[id].push({
            label: this.$t('Logistics.CountryOptions')[code],
            value: code
          })
        })
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD')).unix() * 1000,
        ReceiverDate: '',
        SearchAllTimeRange: false,
        OrderNum: [],
        Payment: [],
        Logistics: [],
        PaymentSystem: [],
        LogisticsSystem: [],
        PaymentStatus: [],
        LogisticsStatus: [],
        OrderStatus: [],
        Account: [],
        Buyer: '',
        Receiver: '',
        BuyerPhone: '',
        ReceiverPhone: '',
        BuyerEmail: '',
        ReceiverEmail: '',
        SeriesNum: [],
        ProductName: '',
        OfferType: [],
        OfferID: [],
        InvoiceType: [],
        InvoiceNumber: '',
        LogisticsNum: [],
        Origin: []
      }
      this.ChooseIndex = []
      return this.$router.replace({
        path: '/order/process/travel'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    ApplyTimeRange (StartTime, EndTime, FilterParameters) {
      if (FilterParameters) {
        this.SearchFilter.ReceiverDate = this.$dayjs(StartTime).format('YYYY-MM-DD').toString()
      } else {
        this.SearchFilter.StartTime = this.$dayjs(StartTime).unix() * 1000
        this.SearchFilter.EndTime = this.$dayjs(EndTime).unix() * 1000
      }
    },
    OpenUserModal(userID) {
      this.Component = () => import('@/components/member/UserModel')
      this.UserID = userID
      this.UserType = 'Read'
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    OpenNotifyModal (messageType, orderData) {
      this.MessageType = messageType
      this.SelectOrderData = orderData
      this.Component = () => import('@/components/order/SendNotify')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
  }
}
</script>

<style>
#OrderList thead th:first-child {
  text-align: center;
}

#OrderList tbody td:first-child {
  vertical-align: middle;
}
</style>
